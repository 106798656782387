
<div id="content_wrapper" class="rightSidenav2-wrapper">
	<div class="content">

		<div class="header-wrapper header-sm shadow-none">

			<!--application title-->
			<header class="container-fluid">
				<div class="row">
					<div class="col">
						<h1>{{_pageTitle}}</h1>
					</div>
				</div>
			</header>

			<!--actions-->
			<div class="actions fab bottom-right">
				<smd-fab-speed-dial direction="left" animationMode="scale">
					<smd-fab-trigger>
						<button mat-fab matTooltip="Modelli di export" (click)="showUserModelsModal()" [disabled]="downloading">
							<mat-icon>list</mat-icon>
						</button>
					</smd-fab-trigger>
					<smd-fab-actions></smd-fab-actions>
				</smd-fab-speed-dial>
			</div>

		</div>

		<div class="content-body">

			<!--filters-->
			<div class="card mb-3">
				<header class="card-header card-default">
					<h2 class="card-title">
						<i class="zmdi zmdi-cloud-download m-r-10"></i>
						{{_cardTitle}}
					</h2>
				</header>
				<div class="card-block">
					<div class="pc-form-filters">
						<form [formGroup]="_searchForm" (ngSubmit)="search()">
							<div class="row">

								<!--switch entity type-->
								<ng-container [ngSwitch]="entityTypeIdentifier">

									<!--employees-->
									<ng-container *ngSwitchCase="1">
										<div class="col-lg-2">
											<mat-form-field>
												<mat-label>{{_searchEntityLabel}}</mat-label>
												<input matInput formControlName="entityLabel" appAutofocus />
											</mat-form-field>
										</div>
										<div class="col-lg-2">
											<mat-form-field>
												<mat-label>Settore</mat-label>
												<mat-select multiple [(ngModel)]="selectedBusinessAreas" formControlName="businessAreasIds">
													<mat-option *ngFor="let _area of businessAreas" [value]="_area.id">
														{{_area.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</ng-container>

									<!--vehicles-->
									<ng-container *ngSwitchCase="2">
										<div class="col-lg-2">
											<mat-form-field>
												<mat-label>Targa</mat-label>
												<input matInput formControlName="licensePlate" appAutofocus />
											</mat-form-field>
										</div>
										<div class="col-lg-2">
											<mat-form-field>
												<mat-label>Tipologia</mat-label>
												<mat-select class="block" formControlName="vehicleTypeId">
													<mat-option>Seleziona...</mat-option>
													<mat-option *ngFor="let _vehicleType of _vehicleTypes" [value]="_vehicleType.id">
														{{_vehicleType.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</ng-container>

									<!--technological resources-->
									<ng-container *ngSwitchCase="3">
										<div class="col-lg-2">
											<mat-form-field>
												<mat-label>Categoria</mat-label>
												<mat-select formControlName="categoryId" (selectionChange)="categoryIdChange($event)">
													<mat-option>Tutte...</mat-option>
													<mat-option *ngFor="let _category of categories" [value]="_category.id">
														{{_category.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-lg-2">
											<mat-form-field>
												<mat-label>Tipologia</mat-label>
												<mat-select formControlName="articleTypeId" (selectionChange)="articleTypeIdChange($event)">
													<mat-option>Tutte...</mat-option>
													<mat-option *ngFor="let _articletype of filteredArticleTypes" [value]="_articletype.id">
														{{_articletype.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-lg-2">
											<mat-form-field>
												<mat-label>{{_searchEntityLabel}}</mat-label>
												<input matInput formControlName="entityLabel" appAutofocus />
											</mat-form-field>
										</div>
									</ng-container>
								</ng-container>

								<div class="col-2">
									<mat-form-field>
										<mat-label>Tipo documento</mat-label>
										<mat-select class="block" formControlName="documentExpirationType">
											<mat-option [value]="-1">Tutti...</mat-option>
											<mat-option *ngFor="let _fileType of fileTypes" [value]="_fileType.id">
												{{_fileType.name}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-2">
									<mat-form-field>
										<mat-label>Scadenza entro</mat-label>
										<input type="number" matInput formControlName="documentExpiration" placeholder="Giorni" />
									</mat-form-field>
								</div>
								<div class="col d-flex align-items-center">
									<button type="submit" mat-raised-button color="accent">
										<mat-icon>search</mat-icon>
										<span>{{'Commons.Actions.Search' | translate}}</span>
									</button>
									<button type="button" mat-icon-button class="ml-4" (click)="resetFilter()" matTooltip="Rimuovi tutti i filtri" matTooltipPosition="above">
										<mat-icon>undo</mat-icon>
									</button>
								</div>
							</div>
						</form>
					</div>

					<!--legend-->
					<ul class="report-legend clearfix">
						<li><i class="zmdi zmdi-plus-circle-o-duplicate text-accent m-r-10"></i>Tipologia additiva</li>
					</ul>
					
				</div>
			</div>

			<!--table-->
			<div class="card overflow-hidden">
				<div class="card-block p-0">
					<div class="reporting-container">

						<div class="downloading-panel" *ngIf="downloading">
							<div>
								<em class="zmdi zmdi-stop rotating"></em>
								<span>Attendere il download...</span>
							</div>
						</div>

						<!--export matrix-->
						<a href="#" class="export-matrix-link" (click)="downloadMatrix()">
							<i class="zmdi zmdi-dropbox"></i>Esporta matrice
						</a>

						<div class="reporting-row reporting-row--header">

							<!--left column-->
							<div class="reporting-row--left">
								<ul class="entities-list">
									<li *ngFor="let _entityRow of filteredEntitiesRows">
										<div>

											<!--row select-->
											<mat-checkbox (change)="selectRow($event, _entityRow.entity.id)"></mat-checkbox>

											<!--single entity link-->
											<ng-container [ngSwitch]="entityTypeIdentifier">

												<!--employees-->
												<ng-container *ngSwitchCase="1">
													<a [routerLink]="['/humanresources/employees/edit/', _entityRow.entity.id]" [queryParams]="{tab: 3}" matTooltip="Dettagli" matTooltipPosition="above">
														<span [innerHtml]="_entityRow.entity.label"></span>
													</a>
												</ng-container>

												<!--vehicles-->
												<ng-container *ngSwitchCase="2">
													<a [routerLink]="['/maintenances/vehicles/edit/', _entityRow.entity.id]" matTooltip="Dettagli" matTooltipPosition="above">
														<span [innerHtml]="_entityRow.entity.label"></span>
													</a>
												</ng-container>

												<!--elements-->
												<ng-container *ngSwitchCase="3">
													<a style="max-width:200px" [routerLink]="['/technologicalresources/articlestypes/edit-article/', _entityRow.entity.categoryId, _entityRow.entity.id]" matTooltip="Dettagli" matTooltipPosition="above">
														<strong>{{_entityRow.entity.articleTypeName}}</strong>
														<small *ngIf="_entityRow.entity.number" class="d-block">NUM: {{_entityRow.entity.number}}</small>
														<small *ngIf="_entityRow.entity.registerNumber" class="d-block">MATR: {{_entityRow.entity.registerNumber}}</small>
													</a>
												</ng-container>

											</ng-container>
										</div>
									</li>
								</ul>
							</div>

							<!--middle column-->
							<div class="reporting-row--content">

								<drag-scroll #ScrollableContent>

									<!--file types headers-->
									<ul class="filetypes-list filetypes-list--header">
										<li *ngFor="let _fileType of fileTypes; let _fileTypeIndex = index">

											<!--file type name-->
											<span (click)="sort(_fileTypeIndex)" class="noselect">

												<i class="zmdi zmdi-long-arrow-up" *ngIf="!sortDirection && sortFileTypeIndex == _fileTypeIndex"></i>
												<i class="zmdi zmdi-long-arrow-down" *ngIf="sortDirection && sortFileTypeIndex == _fileTypeIndex"></i>

												{{_fileType.shortName || _fileType.name}}
												<ng-container *ngIf="_fileType.multiple">
													<i class="zmdi zmdi-plus-circle-o-duplicate text-accent"></i>
												</ng-container>
											</span>

											<!--column actions-->
											<ul class="column-actions-list">

												<!--pdf-->
												<li>
													<a href="javascript:void(0)" (click)="downloadColumn(_fileType.id, 1, 'pdf')" [class.disabled]="downloading">
														<i class="material-icons">description</i>PDF
													</a>
												</li>

												<!--column selector-->
												<li>
													<mat-checkbox (change)="selectColumn($event, _fileType.id)" [checked]="selectedColumns.indexOf(_fileType.id) >= 0"></mat-checkbox>
												</li>

												<!--zip-->
												<li>
													<a href="javascript:void(0)" (click)="downloadColumn(_fileType.id, 2, 'zip')" [class.disabled]="downloading">
														<i class="zmdi zmdi-dropbox"></i>ZIP
													</a>
												</li>
											</ul>
										</li>
									</ul>

									<!--checkboxes list-->
									<ul class="filetypes-list noselect" *ngFor="let _entityRow of filteredEntitiesRows; let i=index">
										<li *ngFor="let _fileType of _entityRow.fileTypes" [class.even]="i%2==1">
											<div>

												<!--enabled-->
												<ng-container *ngIf="_fileType != null && _fileType.length > 0">

													<!--quicklinks-->
													<ul class="quicklinks-list">
														<li>
															<a href="#" (click)="downloadSingle(_entityRow.entity.id, _fileType[0].fileTypeName, _fileType[0].fileId)" matTooltip="{{_fileType[0].fileTypeName}}" matTooltipPosition="above">
																{{_fileType[0].fileTypeShortName || _fileType[0].fileTypeName}}
																<ng-container *ngIf="_fileType[0].fileTypeMultiple && _fileType.length > 1">
																	({{_fileType.length}})
																</ng-container>
																<ng-container *ngIf="_fileType[0].fileTypeMultiple">
																	<sup><i class="zmdi zmdi-plus-circle-o-duplicate text-accent"></i></sup>
																</ng-container>
															</a>
														</li>
													</ul>

													<!--selection-->
													<mat-checkbox value="{{_fileType[0].entityFilesId}}"
																	  [class.isValid]="!_fileType[0].entityFilesExpired || (_fileType[0].entityFilesExpired != null && !_fileType[0].entityFilesExpired)"
																	  [class.isInvalid]="_fileType[0].entityFilesExpired != null && _fileType[0].entityFilesExpired"
																	  [(ngModel)]="_fileType[0].checked">
													</mat-checkbox>

													<!--document expiration-->
													<ng-container *ngIf="_fileType[0].entityFilesExpiryDate">
														<small class="d-block"
																 [class.isValid]="!_fileType[0].entityFilesExpired || (_fileType[0].entityFilesExpired != null && !_fileType[0].entityFilesExpired)"
																 [class.isInvalid]="_fileType[0].entityFilesExpired != null && _fileType[0].entityFilesExpired">
															{{_fileType[0].entityFilesExpiryDate | date:'dd/MM/yyyy'}}
														</small>
													</ng-container>

													<ng-container *ngIf="!_fileType[0].entityFilesExpiryDate">
														<small class="d-block">n.s.</small>
													</ng-container>

												</ng-container>

											</div>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>

								</drag-scroll>

							</div>

							<!--right column-->
							<div class="reporting-row--right">

								<!--actions list-->
								<ul class="buttons-list">
									<li *ngFor="let _entityRow of filteredEntitiesRows">
										<a href="javascript:void(0)" (click)="downloadRow(_entityRow.entity.id, 1, 'pdf')" [class.disabled]="downloading" matTooltip="Scarica PDF unificato" matTooltipPosition="above">
											<i class="material-icons">description</i>
											PDF
										</a>
										<a href="javascript:void(0)" (click)="downloadRow(_entityRow.entity.id, 2, 'zip')" [class.disabled]="downloading" matTooltip="Scarica ZIP" matTooltipPosition="above">
											<i class="zmdi zmdi-dropbox"></i>
											ZIP
										</a>
									</li>
								</ul>

							</div>

						</div>
					</div>
				</div>
			</div>

		</div>

	</div>
</div>

<!--user models management-->
<attachments-reporting-user-models *ngIf="fileTypes" #userModelsSelector
											  [reportingTypeId]="entityTypeIdentifier"
											  [fileTypes]="fileTypes"
											  (userModelSelected)="userModelSelected($event)">
</attachments-reporting-user-models>

<!--footer-->
<footer id="footer_wrapper" class="footer_wrapper"></footer>