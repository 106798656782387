
// actions
export enum Actions
{
	EmployeeAttachments = "EmployeeAttachments",
	CustomerOfferAttachments = "CustomerOfferAttachments",
	SupplierOfferAttachments = "SupplierOfferAttachments",
	VehicleAttachments = "VehicleAttachments",
	ArticleTypeElementAttachments = "ArticleTypeElementAttachments",
	InterventionAttachments = "InterventionAttachments",
	OfficeAttachments = "OfficeAttachments",
}

export enum FileTypes
{
	ContrattoLavoro = 10,
	FormazioneSpecifica = 16,
	CorsoFormazioneSpaziConfinati = 17,
	CorsoFormazionePreposto = 18,
	CorsoFormazioneImbragature = 20,
	CorsoFormazioneMaschere = 21,
	CorsoFormazionePrimoSoccorso = 22,
	CorsoFormazioneAntincendio = 24,
	CorsoFormazioneProvaTenutaRisanamentoVideoispezioni = 30,
	ModuloConsegnaDpi = 34,
	IdoneitaSanitaria = 35,
	PatenteAdr = 63,
	LibrettoCircolazione = 66,
	FoglioValiditaCisterna = 69,
	Assicurazione = 70,
	TaraturaCronotachigrafo = 74,
	VerificaGruCenpi = 78,
	InterventoOperatore = 97,
	InterventoCentraleOperativa = 98,
	Esperienza3AnniSpaziConfinati = 103,
	CorsoFormazioneAdr = 104,

	Patente = 2,
	PatenteCqc = 58,
	TesseraTachigrafica = 11,
	CorsoFormazioneGruAutocarro = 25

}

export enum InterventionType
{
	SpurghiCivili = 1,
	SpurghiIndustriali = 2,
	Sopralluogo = 3,
	Videoispezione = 5,
	SpurghiEntiPubblici = 7,
	EscavatoriRisucchio = 8,
	ProntoInterventoSpurghi = 9,
	ProntoInterventoAmbientale = 10,
	ProntoInterventoAllagamenti = 11
}

export enum InterventionStatus
{
	Inserted = 1,
	Scheduled = 2,
	Deleted = 3,
	Active = 4,
	Completed = 5,
	Canceled = 6,
	Brea = 7,
	Travelling = 8,
	OnSite = 9
}

export enum InterventionPurgeWorkTeamStatus
{
	Inserted = 1,
	Scheduled = 2,
	Active = 3,
	Completed = 4,
	Canceled = 5,
	Break = 6,
	Travelling = 7,
	OnSite = 8
}
export enum PurgeNotePurgeWorkTeamStatus
{
	Inserted = 1,
	Scheduled = 2,
	Active = 3,
	Completed = 4,
	Canceled = 5,
	Break = 6,
	Travelling = 7,
	OnSite = 8
}

export enum VehicleType
{
	Spurghi = 1,
	FurgoniSpurghi = 2,
	FurgoniTeleispezione = 9,
}

export enum InterventionParameter
{
	SpaziConfinati = 1,
	PuliziaVascheLagune = 2,
	PuliziaDigestoriAnaerobici = 3,
	PuliziaSerbatoiCombustibili = 4,
	PuliziaSerbatoiProcesso = 5,
	PuliziaIdrodinamicaTubazioni = 6,
	RisanamentoTubazioni = 7,
	MappaturaRetiFognarieAltreReti = 8,
	ProveTenutaSerbatoi = 9,
	ProveTenutaTubazioneCondotteFognarie = 10,
	InterventoAdr = 11
}