<!--header-->
<div class="modal-header">
	<h4 class="modal-title d-flex align-items-center float-left">
		<mat-icon class="mr-2">checklist</mat-icon>
		Validazione intervento
	</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--body content-->
<div class="modal-body">

	<!--intervention validation-->
	<intervention-validation #interventionValidationComponent
									 [id]="inputData.id">

	</intervention-validation>

</div>

<!--footer-->
<div class="modal-footer">
	<button type="button" mat-button class="mr-2" (click)="hideModal()">
		Annulla
	</button>
	<button type="button" (click)="confirmAndSchedule()" [disabled]="!interventionValidationComponent.ready || !interventionValidationComponent.validationData.valid || scheduling" mat-raised-button color="accent">
		<mat-icon class="mr-2">check_all</mat-icon>
		<span>Conferma e programma intervento</span>
	</button>
</div>