<!--header-->
<div class="modal-header">
	<h4 class="modal-title float-left">
		<i class="zmdi zmdi-truck m-r-10"></i>Seleziona automezzo
	</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!--body-->
<div class="modal-body">

	<!--filters-->
	<div class="pc-form-filters">
		<form [formGroup]="_searchForm" (ngSubmit)="search()">
			<div class="row">
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>Targa / Nome / N.SIA / Barcode</mat-label>
						<input type="text" matInput formControlName="name" #modalAutofocusRef />
					</mat-form-field>
				</div>
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>Tipologia</mat-label>
						<mat-select formControlName="vehicleTypeId" (selectionChange)="vehicleTypeSelectionChange()">
							<mat-option>Tutte</mat-option>
							<mat-option *ngFor="let _vehicleType of _vehicleTypes" [value]="_vehicleType.id">
								<span>{{_vehicleType.name }}</span>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>Taglia max.</mat-label>
						<mat-select formControlName="vehicleSizeId">
							<mat-option *ngIf="vehicleTypeId.value && _vehicleSizes && _vehicleSizes.length > 0">Tutte</mat-option>
							<mat-option *ngIf="!vehicleTypeId.value">Seleziona prima la tipologia automezzo</mat-option>
							<mat-option *ngIf="vehicleTypeId.value && (!_vehicleSizes || _vehicleSizes.length === 0)">Nessuna taglia disponibile</mat-option>
							<mat-option *ngFor="let _vehicleSize of _vehicleSizes" [value]="_vehicleSize.id">
								<span>{{_vehicleSize.name}}</span>
								<small *ngIf="_vehicleSize.description" class="mat_option_description"> ({{_vehicleSize.description}})</small>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>N° posti</mat-label>
						<mat-select formControlName="seats">
							<mat-option>Tutti</mat-option>
							<mat-option [value]="2">2 posti</mat-option>
							<mat-option [value]="3">3 posti</mat-option>
							<mat-option [value]="5">5 posti</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>ADR</mat-label>
						<mat-select formControlName="adr">
							<mat-option>Tutti</mat-option>
							<mat-option [value]="true">Sì</mat-option>
							<mat-option [value]="false">No</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Altezza max.</mat-label>
						<input type="number" matInput formControlName="maxHeight" />
					</mat-form-field>
				</div>
				<div class="col-lg-2">
					<mat-form-field>
						<mat-label>Diametro tubaz. max.</mat-label>
						<input type="number" matInput formControlName="maxPipeDiameter" />
					</mat-form-field>
				</div>
				<div class="col-lg-3">
					<mat-form-field>
						<mat-label>Opzioni</mat-label>
						<mat-select formControlName="optionsId" multiple>
							<mat-optgroup label="Autospurghi">
								<mat-option [value]="1">Spurgo industriale</mat-option>
								<mat-option [value]="2">Spurgo enti pubblici</mat-option>
								<mat-option [value]="3">Proboscide per caditoie</mat-option>
							</mat-optgroup>
							<mat-optgroup label="Furgoni">
								<mat-option [value]="4">Teleispezione</mat-option>
								<mat-option [value]="5">Risanamento</mat-option>
								<mat-option [value]="6">Cassone</mat-option>
								<mat-option [value]="7">Chiuso</mat-option>
								<mat-option [value]="8">Furgone con gru</mat-option>
								<mat-option [value]="9">Trasporto rifiuti</mat-option>
							</mat-optgroup>
							<mat-optgroup label="Altro">
								<mat-option [value]="10">Area B Milano</mat-option>
								<mat-option [value]="11">ZTL Bergamo</mat-option>
							</mat-optgroup>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col d-flex align-items-center">
					<button type="submit" mat-raised-button color="accent">
						<mat-icon>search</mat-icon>
						<span>{{'Commons.Actions.Search' | translate}}</span>
					</button>
					<button type="button" mat-icon-button class="ml-4" (click)="resetFilter()" matTooltip="Rimuovi tutti i filtri" matTooltipPosition="above">
						<mat-icon>undo</mat-icon>
					</button>
				</div>
			</div>
		</form>
	</div>

	<!--results-->
	<div class="table-responsive">
		<table datatable id="VehiclesDatatable" name="VehiclesDatatable" *ngIf="_showTable" [dtOptions]="_dtOptions" class="table row-border hover"></table>
	</div>

</div>