<ng-container *ngIf="validationData">
	<!--validation header-->
	<div class="validation_header">

		<!--validation legend-->
		<ul class="validation_legend">
			<li>
				<mat-icon class="text-success">done</mat-icon>
				<span>{{validControlCount}} verificat{{validControlCount === 1 ? 'o' : 'i'}}</span>
			</li>
			<li>
				<mat-icon class="text-danger">clear</mat-icon>
				<span>{{invalidControlCount}} non valid{{invalidControlCount === 1 ? 'o' : 'i'}}</span>
			</li>
			<li>
				<mat-icon class="text-bypass">warning_amber</mat-icon>
				<span>{{bypassControlCount}} ignorat{{bypassControlCount === 1 ? 'o' : 'i'}}</span>
			</li>
		</ul>

		<!--refresh-->
		<button mat-icon-button color="accent" (click)="refreshValidation()" matTooltip="Aggiorna" *ngIf="!historic">
			<mat-icon>refresh</mat-icon>
		</button>

	</div>

	<!--validation area-->
	<div class="validations_container">

		<!--check: intervention data-->
		<ng-container *ngIf="ready">

			<!--loop validation areas-->
			<ng-container>
				<ng-template [ngTemplateOutlet]="ValidationAreaTemplate"
								 [ngTemplateOutletContext]="{ validationArea: validationArea, validationAreaIndex: i.toString() }"
								 *ngFor="let validationArea of validationData.areas; let i = index">
				</ng-template>
			</ng-container>

		</ng-container>

		<!--loading intervention data-->
		<div *ngIf="!ready">
			Caricamento informazioni dell'intervento...
		</div>

	</div>
</ng-container>

<!--no validation data-->
<ng-container *ngIf="ready && !validationData">
	<div class="d-flex align-items-center">
		<mat-icon class="mr-2">info</mat-icon>
		<span>Nessuna informazione di validazione per questo intervento.</span>
	</div>
</ng-container>

<!--validation area template-->
<ng-template #ValidationAreaTemplate let-validationArea="validationArea" let-validationAreaIndex="validationAreaIndex">

	<!--areas-->
	<div class="validation_area">

		<!--area title-->
		<div class="validation_area_title" (click)="!historic && expandValidationArea(validationAreaIndex)">
			<h2>
				<mat-icon>{{validationArea.icon}}</mat-icon>
				<span>
					{{validationArea.title}}
					<small>{{validationArea.description || 'Clicca per visualizzare i dettagli'}}</small>
				</span>
			</h2>
			<div class="validation_area_status" *ngIf="!historic">
				<mat-icon class="text-success" *ngIf="validationArea.valid">done</mat-icon>
				<mat-icon class="text-danger" *ngIf="!validationArea.valid">clear</mat-icon>
			</div>
		</div>

		<!--check: hidden area-->
		<div [class.hidden]="validationArea.hidden && !historic">

			<!--loop children validation areas-->
			<ng-template [ngTemplateOutlet]="ValidationAreaTemplate"
							 [ngTemplateOutletContext]="{ validationArea: validationArea, validationAreaIndex: validationAreaIndex + '|' + i }"
							 *ngFor="let validationArea of validationArea.areas; let i = index">
			</ng-template>

			<!--sections-->
			<ul class="validation_sections_list">
				<li *ngFor="let section of validationArea.sections">

					<h4>
						<mat-icon>arrow_right</mat-icon>
						<span>{{section.title}}</span>
					</h4>

					<!--items-->
					<ul class="validation_items_list">
						<li *ngFor="let item of section.items">

							<!--content-->
							<div class="validation_item_content">

								<!--header-->
								<div class="validation_item_header">

									<!--status-->
									<div class="validation_item_status">
										<mat-icon *ngIf="item.valid" class="text-success">done</mat-icon>
										<mat-icon *ngIf="!readOnly && !item.valid && item.bypassValidation" class="text-bypass">warning_amber</mat-icon>
										<mat-icon *ngIf="!item.valid && (!item.bypassValidation || readOnly)" class="text-danger">clear</mat-icon>
									</div>

									<!--title-->
									<span [class.text-success]="item.valid"
											[class.text-bypass]="!readOnly && !item.valid && item.bypassValidation"
											[class.text-danger]="!item.valid && (!item.bypassValidation || readOnly)">
										{{item.title | uppercase}}
									</span>

								</div>

								<!--summary-->
								<small *ngIf="item.summary">
									{{item.summary}}
								</small>

								<!--description-->
								<div class="validation_item_description" [innerHtml]="item.description"></div>

							</div>

							<!--bypass validation-->
							<div class="validation_item_bypass" *ngIf="!readOnly && !item.valid">
								<mat-slide-toggle [checked]="item.bypassValidation"
														matTooltip="Ignora verifica"
														(change)="bypassValidationChange($event, item)">
								</mat-slide-toggle>
							</div>

						</li>
					</ul>

				</li>
			</ul>

		</div>
	</div>

</ng-template>