import { GpsHistory } from '../../../shared/commons/gps-history-map/gps-history-map.models';
import { PurgeWorkTeam } from '../../../shared/purges/purgeworkgroups/purgeworkgroups.models';
import { DigifranTimeLine, DigifranTimeLineEvent } from '../../../shared/timeline/timeline.models';

export interface InterventionListItemWorkTeam
{
	id: number
	lead: boolean
	entityPurgeWorkTeamStatusId: number
	worksheetNotes?: string
	worksheetPipeNotes?: string
	worksheetPresumedCubicMeters?: number
	worksheetWorkTime?: number
	worksheetTravelTime?: number
	employees: InterventionListItemWorkTeamEmployee[]
	vehicles: InterventionListItemWorkTeamVehicle[]
}
export interface InterventionListItemWorkTeamEmployee
{
	id: number
	teamLeader: boolean
	lastName: string
	firstName: string
}
export interface InterventionListItemWorkTeamVehicle
{
	id: number
	licensePlate: string
}

export interface Intervention
{
	id: number;
	subscriptionId: number;
	customerId: number;
	officeId: number;
	interventionTypeId: number;
	interventionStatusId: number;
	invoiceTypeId: number;
	requestedBy: string;
	vehicleNotes: string;
	notes: string;
	referent: string;
	referentPhoneNumber: string;
	schedulingDate: string;
	schedulingHour: number;
	schedulingMinute: number;
	schedulingInfo: string;
	workingGroupNotes: string;
	interventionVehicleTypeId: number;
	interventionPriorityId: number;
	highlight: boolean;
	highlightNotes: string;
	noSubscription: boolean;
	claim: boolean;
	claimNotes: string;
	worksheetNotes: string;
	migrationId: number;
	migrationCustomer: string;
	migrationAddress: string;
	migrationCity: string;
	migrationProvince: string;
	migrationPostalCode: string;
	migrationOperator: string;
	migrationCollaborator: string;
	migrationVehicle: string;
	migrationReferent: string;
	migrationBuildingAdministrator: string;
	migrationTelephoneNumber: string;
	sendToDevice: boolean;
	limitedUpdate: boolean;
	subscriptionOfficeId: number
	subscriptionDescription: string;
	interventionStatusDescription: string;
	standby: boolean;
	standbyNotes: string;

	// purge work teams
	purgeWorkTeams: InterventionWorkTeam[];

	// helpers
	customer: any;
	office: any;
}
export interface InterventionWorkTeam extends PurgeWorkTeam
{
	interventionPurgeWorkTeamStatusId: number;
	notes: string;
	worksheetNotes: string;
	worksheetPipeNotes: string;
	worksheetWorkTimeHours: number;
	worksheetWorkTimeMinutes: number;
	worksheetTravelTimeHours: number;
	worksheetTravelTimeMinutes: number;
}

export interface GetNoSubscriptionResponse
{
	noSubscription: boolean;
	recurringPrice?: number;
	noNetwork?: boolean;
	notes: string;
}
export interface GetNextSubscriptionInterventionResponse
{
	id: number;
	date: Date;
	frequency: number;
	description: string;
}

// intervention registry
export interface InterventionRegistry
{
	interventionId: number;

	// logs
	interventionLogs: InterventionLog[];
}

// intervention work team registry
export interface InterventionWorkTeamRegistry
{
	interventionId: number;
	interventionScheduling: Date;
	interventionCustomer: InterventionWorkTeamRegistryCustomer;

	// purge work teams
	interventionPurgeWorkTeams: InterventionWorkTeamRegistryPurgeWorkTeam[];
}
export interface InterventionWorkTeamRegistryCustomer
{
	businessName: string;
	office: InterventionWorkTeamRegistryOffice;
}
export interface InterventionWorkTeamRegistryOffice
{
	address: string;
	latitude: number;
	longitude: number;
}
export interface InterventionWorkTeamRegistryPurgeWorkTeam
{
	id: number;
	lead: boolean;
	interventionPurgeWorkTeamStatusId: number;
	interventionPurgeWorkTeamStatusName: string;
	worksheetNotes: string;
	worksheetPipeNotes: string;
	worksheetPresumedCubicMeters: number;
	worksheetWorkTimeHours: number;
	worksheetWorkTimeMinutes: number;
	worksheetTravelTimeHours: number;
	worksheetTravelTimeMinutes: number;
	cancelNotes: string;

	// operators
	operators: InterventionWorkTeamRegistryPurgeWorkTeamOperator[];

	// vehicles
	vehicles: InterventionWorkTeamRegistryPurgeWorkTeamVehicle[];

	// logs
	interventionPurgeWorkTeamLogs: InterventionLog[];

	// timeline
	timeline: DigifranTimeLine;

	// locations
	interventionLocation: InterventionDeviceLocation;
}
export interface InterventionWorkTeamRegistryPurgeWorkTeamOperator
{
	id: number;
	lastName: string;
	firstName: string;
	teamLeader: boolean;
}
export interface InterventionWorkTeamRegistryPurgeWorkTeamVehicle
{
	id: number;
	name: string;
	licensePlate: string;
	mounting: string;
}

export interface InterventionDeviceLocation
{
	source: string;
	history: GpsHistory[];
}

export interface InterventionLog
{
	id: number;
	description: string;
	interventionLogActionDescription: string;
	interventionLogActionId: number;
	interventionLogActionTitle: string;
	interventionLogActionIcon: string;
	interventionLogActionIconColor: string;
	interventionLogActionWebApp: boolean;
	insertUserFullName: string;
	insertDate: Date;
}

export interface ChangeInterventionStatusViewModel
{
	id: number;
	interventionStatusId: number;
	interventionStatusDescription: string;
	interventionTypeId: number;
	offerCode: string;
	scheduling?: Date;

	// work teams
	interventionWorkTeams: ChangeInterventionStatusWorkTeamViewModel[];

	// helpers
	customerBusinessName: string;
}
export interface ChangeInterventionStatusWorkTeamViewModel
{
	id: number;
	lead: boolean;
	interventionPurgeWorkTeamStatusId: number;
	cancelNotes: string;
	teamLeaderOperatorFullName: string;
}

// timeline

export class InterventionTimeLineEvent extends DigifranTimeLineEvent
{
	title: string;
	description: string;
	message: string;
	user: string;
}

export class InterventionWorkTeamTimeLineEvent extends DigifranTimeLineEvent
{
	title: string;
	description: string;
	message: string;
	user: string;
}

// operator notes

export class ChangeInterventionOperatorNotesViewModel
{
	interventionsPurgeWorkTeamsId: number;

	worksheetNotes: string;
	worksheetPipesNotes: number;
	worksheetPresumedCubicMeters: number;

	// work time
	worksheetWorkTimeHours: number;
	worksheetWorkTimeMinutes: number;

	// travel time
	worksheetTravelTimeHours: number;
	worksheetTravelTimeMinutes: number;
}

// enums

export enum InterventionLogActionType
{
	StartNavigation = 1,
	StopNavigation = 2,
	OpenNavigator = 3,
	CallPhoneNumber = 4,
	UpdateNotes = 5,
	CancelIntervention = 6,
	StartBreak = 7,
	StopBreak = 8,
	StartIntervention = 9,
	CompleteIntervention = 10,
	UploadPhoto = 11,

	Create = 101,
	Update = 102,
	Delete = 103,
	SendToDevice = 104,
	ManualStatusChange = 105,
	UploadAttachment = 106,
	DeleteAttachment = 107
}