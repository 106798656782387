import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InterventionsService } from '../../../../pages/purges/interventions/interventions.service';
import { InterventionValidationComponent } from '../intervention-validation/intervention-validation.component';

@Component({
	selector: 'intervention-validation-dialog',
	templateUrl: './intervention-validation-dialog.component.html',
	styleUrls: ["./intervention-validation-dialog.component.scss"]
})

export class InterventionValidationDialogComponent
{
	// I/O
	@Output() submitCallback: EventEmitter<any> = new EventEmitter();

	// viewchildren
	@ViewChild("interventionValidationComponent") public interventionValidationComponent: InterventionValidationComponent;

	// input data
	public inputData: { id: number };

	// variables
	public scheduling = false;

	// constructor
	constructor
		(
			private interventionsService: InterventionsService,
			public bsModalRef: BsModalRef
		)
	{

	}

	// init
	ngOnInit()
	{
		
	}

	// confirm and schedule
	confirmAndSchedule()
	{
		this.scheduling = true;

		// update intervention validation
		this.interventionsService.updateValidation(this.inputData.id, JSON.stringify(this.interventionValidationComponent.validationData)).subscribe(
			result =>
			{
				// emit callback
				this.submitCallback.emit();
			},
			error =>
			{
				this.scheduling = false;
			});
	}

	// hide modal
	hideModal()
	{
		this.bsModalRef.hide();
	}
}
