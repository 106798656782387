import { NgxUploaderModule } from '@angular-ex/uploader';
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { DataTablesModule } from "angular-datatables";
import { GaugeModule } from 'angular-gauge';
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FooterComponent } from "../../app/admin/layout/footer/footer.component";
import { BuildingAdministratorsSelectorComponent } from '../../app/admin/shared/registries/buildingadministrators/selector/selector.component';
import { CustomerSelectorComponent } from "../../app/admin/shared/registries/customers/selector/selector.component";
import { EmployeeSelectorComponent } from "../../app/admin/shared/registries/employees/selector/selector.component";
import { ProducerSelectorComponent } from '../../app/admin/shared/registries/producers/selector/selector.component';
import { SupplierSelectorComponent } from '../../app/admin/shared/registries/suppliers/selector/selector.component';
import { WasteCatalogSelectorComponent } from '../../app/admin/shared/registries/wastecatalog/selector/selector.component';
import { FileTypesService } from '../admin/pages/configurations/filetypes/filetypes.service';
import { ChangeInterventionStatusDialogComponent } from "../admin/pages/purges/interventions/change-intervention-status-dialog/change-intervention-status-dialog.component";
import { InterventionLogsDialogComponent } from "../admin/pages/purges/interventions/intervention-logs-dialog/intervention-logs-dialog.component";
import { InterventionValidationDialogComponent } from '../admin/pages/purges/interventions/intervention-validation-dialog/intervention-validation-dialog.component';
import { InterventionValidationComponent } from '../admin/pages/purges/interventions/intervention-validation/intervention-validation.component';
import { ChangePurgeNoteStatusDialogComponent } from "../admin/pages/purges/purgenotes/change-purge-note-status-dialog/change-purge-note-status-dialog.component";
import { PurgeNoteLogsDialogComponent } from "../admin/pages/purges/purgenotes/purge-note-logs-dialog/purge-note-logs-dialog.component";
import { CatalogArticleLogsModalComponent } from "../admin/pages/technologicalresources/articletypes/article-logs-modal/article-logs-modal.component";
import { CatalogNewArticlePartsModalComponent } from "../admin/pages/technologicalresources/articletypes/new-article-parts-modal/new-article-parts-modal.component";
import { TransportMissionChangeStatusComponent } from "../admin/pages/transport/missions/change-status-modal/changestatusmodal.component";
import { ReportingMatrixComponent } from "../admin/shared/attachments-reporting/reporting-matrix/reporting-matrix.component";
import { AttachmentsReportingUserModelsComponent } from "../admin/shared/attachments-reporting/user-models/user-models.component";
import { GpsHistoryMapComponent } from '../admin/shared/commons/gps-history-map/gps-history-map.component';
import { VehicleSelectorComponent } from "../admin/shared/maintenances/vehicles/selector/selector.component";
import { CustomersOffersSelectorComponent } from '../admin/shared/offers/customersoffers/selector/selector.component';
import { SuppliersOffersSelectorComponent } from '../admin/shared/offers/suppliersoffers/selector/selector.component';
import { SuppliersOffersActivitiesSelectorComponent } from '../admin/shared/offers/suppliersoffersactivities/selector/selector.component';
import { PurgeWorkGroupDailyWorksheetComponent } from "../admin/shared/purges/purgeworkgroups/daily-worksheet/daily-worksheet.component";
import { PurgeWorkTeamsComponent } from "../admin/shared/purges/purgeworkgroups/purge-work-teams/purge-work-teams.component";
import { PurgeWorkGroupSelectorComponent } from "../admin/shared/purges/purgeworkgroups/selector/selector.component";
import { DisposerSelectorComponent } from "../admin/shared/registries/disposers/selector/selector.component";
import { IntermediarySelectorComponent } from "../admin/shared/registries/intermediaries/selector/selector.component";
import { EditOfficeComponent } from '../admin/shared/registries/offices/edit-office.component';
import { TransporterSelectorComponent } from "../admin/shared/registries/transporters/selector/selector.component";
import { TechnologicalResourceTypeSelectorComponent } from "../admin/shared/technologicalresources/selector/selector.component";
import { DigifranTimeLineComponent } from "../admin/shared/timeline/timeline.component";
import { EditActivityComponent } from "../admin/shared/timetable/deadlineactivity/edit-activity.component";
import { TransportWorkingGroupSelectorComponent } from "../admin/shared/transport/workinggroups/selector/selector.component";
import { AppBackdropComponent } from "./components/app_backdrop/app_backdrop.component";
import { SmdFabSpeedDialActionsComponent, SmdFabSpeedDialComponent, SmdFabSpeedDialTriggerComponent } from "./components/fab";
import { TaskComponent } from "./components/todo/task.component";
import { TodoComponent } from "./components/todo/todo.component";
import { UploadComponent } from "./components/upload/upload.component";
import { WeatherComponent } from "./components/weather/weather.component";
import { AutofocusDirective } from './directives/autoFocus.directive';
import { AlertsDialogComponent } from "./services/alerts/alerts-dialog/alerts-dialog.component";
import { FilesService } from './services/app/files.service';
import { SetupService } from './services/app/setup.service';
import { pCodeDateAdapter } from './services/pcode/pcode-date-adapter';
import { pCodePaginatorIntl } from './services/pcode/pcode-paginator-intl';

@NgModule({
	imports:
		[
			CommonModule,
			FormsModule,
			ReactiveFormsModule,
			RouterModule,
			NgScrollbarModule,
			MatAutocompleteModule,
			MatButtonModule,
			MatButtonToggleModule,
			MatCardModule,
			MatCheckboxModule,
			MatChipsModule,
			MatDatepickerModule,
			MatDialogModule,
			MatExpansionModule,
			MatGridListModule,
			MatIconModule,
			MatInputModule,
			MatListModule,
			MatMenuModule,
			MatNativeDateModule,
			MatProgressBarModule,
			MatProgressSpinnerModule,
			MatRadioModule,
			MatRippleModule,
			MatSelectModule,
			MatSidenavModule,
			MatSliderModule,
			MatSlideToggleModule,
			MatSnackBarModule,
			MatTabsModule,
			MatStepperModule,
			MatToolbarModule,
			MatTooltipModule,
			NgxChartsModule,
			GaugeModule.forRoot(),
			BsDropdownModule.forRoot(),
			AlertModule.forRoot(),
			TabsModule.forRoot(),
			ModalModule.forRoot(),
			PopoverModule.forRoot(),
			TranslateModule,
			NgxUploaderModule,
			NgxMatSelectSearchModule,
			DataTablesModule,

			// drag scroll
			DragScrollComponent,
			DragScrollItemDirective,
		],
	declarations:
		[
			AppBackdropComponent,
			FooterComponent,
			TodoComponent,
			TaskComponent,
			WeatherComponent,
			UploadComponent,
			SmdFabSpeedDialActionsComponent,
			SmdFabSpeedDialComponent,
			SmdFabSpeedDialTriggerComponent,

			EditOfficeComponent,

			PurgeWorkTeamsComponent,
			PurgeWorkGroupDailyWorksheetComponent,
			TransportMissionChangeStatusComponent,
			ReportingMatrixComponent,
			AttachmentsReportingUserModelsComponent,
			AutofocusDirective,
			EditActivityComponent,
			AlertsDialogComponent,
			CatalogNewArticlePartsModalComponent,
			CatalogArticleLogsModalComponent,
			InterventionValidationDialogComponent,
			InterventionValidationComponent,

			// global selectors and dialogs
			CustomerSelectorComponent,
			TransportWorkingGroupSelectorComponent,
			EmployeeSelectorComponent,
			VehicleSelectorComponent,
			PurgeWorkGroupSelectorComponent,
			SupplierSelectorComponent,
			ProducerSelectorComponent,
			DisposerSelectorComponent,
			TransporterSelectorComponent,
			IntermediarySelectorComponent,
			WasteCatalogSelectorComponent,
			SuppliersOffersSelectorComponent,
			CustomersOffersSelectorComponent,
			BuildingAdministratorsSelectorComponent,
			SuppliersOffersActivitiesSelectorComponent,
			TechnologicalResourceTypeSelectorComponent,
			ChangeInterventionStatusDialogComponent,
			InterventionLogsDialogComponent,
			PurgeNoteLogsDialogComponent,
			ChangePurgeNoteStatusDialogComponent,
			DigifranTimeLineComponent,

			GpsHistoryMapComponent
		],
	exports:
		[
			CommonModule,
			FormsModule,
			MatAutocompleteModule,
			MatButtonModule,
			MatButtonToggleModule,
			MatCardModule,
			MatCheckboxModule,
			MatChipsModule,
			MatDatepickerModule,
			MatDialogModule,
			MatExpansionModule,
			MatGridListModule,
			MatIconModule,
			MatInputModule,
			MatListModule,
			MatMenuModule,
			MatNativeDateModule,
			MatProgressBarModule,
			MatProgressSpinnerModule,
			MatRadioModule,
			MatRippleModule,
			MatSelectModule,
			MatSidenavModule,
			MatSliderModule,
			MatSlideToggleModule,
			MatSnackBarModule,
			MatTabsModule,
			MatStepperModule,
			MatToolbarModule,
			MatTooltipModule,
			DataTablesModule,
			ReactiveFormsModule,
			RouterModule,
			NgScrollbarModule,
			TabsModule,
			BsDropdownModule,
			AlertModule,
			ModalModule,
			PopoverModule,
			GaugeModule,
			NgxChartsModule,
			TranslateModule,
			NgxUploaderModule,
			NgxMatSelectSearchModule,
			AppBackdropComponent,
			FooterComponent,
			TodoComponent,
			TaskComponent,
			WeatherComponent,
			UploadComponent,
			SmdFabSpeedDialActionsComponent,
			SmdFabSpeedDialComponent,
			SmdFabSpeedDialTriggerComponent,
			EditOfficeComponent,
			PurgeWorkTeamsComponent,
			PurgeWorkGroupDailyWorksheetComponent,
			TransportMissionChangeStatusComponent,
			ReportingMatrixComponent,
			AttachmentsReportingUserModelsComponent,
			AutofocusDirective,
			EditActivityComponent,
			CatalogNewArticlePartsModalComponent,
			CatalogArticleLogsModalComponent,
			InterventionValidationDialogComponent,
			InterventionValidationComponent,

			// global selectors and dialogs
			CustomerSelectorComponent,
			TransportWorkingGroupSelectorComponent,
			EmployeeSelectorComponent,
			VehicleSelectorComponent,
			PurgeWorkGroupSelectorComponent,
			SupplierSelectorComponent,
			ProducerSelectorComponent,
			DisposerSelectorComponent,
			TransporterSelectorComponent,
			IntermediarySelectorComponent,
			WasteCatalogSelectorComponent,
			BuildingAdministratorsSelectorComponent,
			SuppliersOffersSelectorComponent,
			CustomersOffersSelectorComponent,
			SuppliersOffersActivitiesSelectorComponent,
			TechnologicalResourceTypeSelectorComponent,
			ChangeInterventionStatusDialogComponent,
			InterventionLogsDialogComponent,
			PurgeNoteLogsDialogComponent,
			ChangePurgeNoteStatusDialogComponent,
			DigifranTimeLineComponent,

			GpsHistoryMapComponent,

			// drag scroll
			DragScrollComponent,
			DragScrollItemDirective,
		],

	// providers
	providers:
		[
			{ provide: DateAdapter, useClass: pCodeDateAdapter },
			{ provide: MatPaginatorIntl, useClass: pCodePaginatorIntl },

			// project common services
			FilesService,
			FileTypesService,
			SetupService
		]
})
export class SharedModule
{
	static forRoot(): ModuleWithProviders<SharedModule>
	{
		return {
			ngModule: SharedModule
		};
	}
}
