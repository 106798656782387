import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PurgeWorkType, VehicleSize, VehicleType } from '../../../admin/shared/shared.models';

@Injectable()
export class SetupService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get appointment types
	public getAppointmentTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getAppointmentTypes');
	}

	// get blacklist types
	public getBlacklistTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getBlacklistTypes');
	}

	// get business areas
	public getBusinessAreas()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getBusinessAreas');
	}

	// get customer types
	public getCustomerTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getCustomerTypes');
	}

	// get employee types
	public getEmployeeTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getEmployeeTypes');
	}

	// get intervention priorities
	public getInterventionPriorities(): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/shared/setup/getInterventionPriorities');
	}

	// get intervention status
	public getInterventionStatus()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getInterventionStatus');
	}

	// get intervention types
	public getInterventionTypes(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(this.baseUrl + `api/shared/setup/getInterventionTypes`);
	}

	// get intervention parameters
	public getInterventionParameters(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(this.baseUrl + `api/shared/setup/getInterventionParameters`);
	}

	// get invoice types
	public getInvoiceTypes(): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/shared/setup/getInvoiceTypes');
	}

	// get maintenance counters
	public getMaintenanceCounters()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getMaintenanceCounters');
	}

	// get maintenance frequency types
	public getMaintenanceFrequencyTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getMaintenanceFrequencyTypes');
	}

	// get muffle positions
	public getMufflePositions()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getMufflePositions');
	}

	// get nations
	public getNations()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/GetNations');
	}

	// get offer status
	public getOfferStatus()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/GetOfferStatus');
	}

	// get offer types
	public getOfferTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/GetOfferTypes');
	}

	// get oven positions
	public getOvenPositions()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getOvenPositions');
	}

	// get provinces
	public getProvinces()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getProvinces');
	}

	// get provinces autocomplete
	public getProvincesAutocomplete(Input: string)
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getProvincesAutocomplete?Input=' + Input);
	}

	// get purge note types
	public getPurgeNoteTypes(DigifranVisibility: boolean, WebAppVisibility: boolean): Observable<[]>
	{
		return this.http.get<[]>(this.baseUrl + 'api/shared/setup/getPurgeNoteTypes?DigifranVisibility=' + DigifranVisibility + '&WebAppVisibility=' + WebAppVisibility);
	}

	// get intervention types
	public getPurgeWorkTypes(): Observable<PurgeWorkType[]>
	{
		return this.http.get<PurgeWorkType[]>(this.baseUrl + 'api/shared/setup/getPurgeWorkTypes');
	}

	// get regions
	public getRegions()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getRegions');
	}

	// get subscription options
	public getSubscriptionOptions()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getSubscriptionOptions');
	}

	// get timetable frequency types
	public getTimetableFrequencyTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getTimetableFrequencyTypes');
	}

	// get towns
	public getTowns(ProvinceIstatCode: string)
	{
		return this.http.get<any[]>(this.baseUrl + 'api/shared/setup/getTowns?ProvinceIstatCode=' + ProvinceIstatCode);
	}

	// get towns autocomplete
	public getTownsAutocomplete(Input: string, ProvinceIstatCode?: string)
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getTownsAutocomplete?Input=' + Input + (ProvinceIstatCode ? '&ProvinceIstatCode=' + ProvinceIstatCode : ''));
	}

	// get vehicle sizes
	public getVehicleSizes(vehicleTypeId?: number): Observable<VehicleSize[]>
	{
		return this.http.get<VehicleSize[]>(this.baseUrl + `api/shared/setup/getVehicleSizes?vehicleTypeId=` + vehicleTypeId);
	}

	// get vehicle types
	public getVehicleTypes(): Observable<VehicleType[]>
	{
		return this.http.get<VehicleType[]>(this.baseUrl + 'api/shared/setup/getVehicleTypes');
	}

	// get appointment types
	public getVehiclesUnavailabilityTypes()
	{
		return this.http.get(this.baseUrl + 'api/shared/setup/getVehiclesUnavailabilityTypes');
	}

}